<template>
  <v-container
    class="fill-height align-center justify-center"
    fluid
  >
    <v-btn
      color="primary"
      @click="login"
      x-large
    >
      <v-icon
        class="mr-2"
      >
        mdi-login
      </v-icon>
      Login
    </v-btn>
  </v-container>
</template>

<script>
export default {
  name: 'LoginView',
  data: () => ({}),
  methods: {
    async login () {
      await this.$auth.signInWithRedirect({ originalUri: this.$route.query.redirect || '/' })
    }
  }
}
</script>
